<template>
<Reg-layouts>
   <div class="main__content">

        <form class="main__form" autocomplete="off" v-on:submit.prevent="signup">
            <div class="section section_mobile-secondary">
                <div class="section__header">
                    <div class="section__wysiwyg wysiwyg">
                        <h1>Create An Account {{this.user.register_role.charAt(0).toUpperCase() + this.user.register_role.slice(1)}} </h1>
                    </div>
                </div>
                <div class="section__content">

                    <div class="account">
                    <div class="account__steps steps">
                        <div class="steps__item step">
                            <div class="step__header">
                                <div class="step__number">
                                    <span class="number-text">1</span>
                                </div>
                                <div class="step__title">
                                    <span class="title-text">Information</span>
                                </div>
                            </div>
                            <div class="step__content">
                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">First Name *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="firstname" class="form-field__input" type="text"
                                                        placeholder="Please enter first name"
                                                        :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Last Name *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input v-model="lastname" class="form-field__input" type="text"
                                                        placeholder="Please enter last name"
                                                        :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col col_6 col_mob-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label v-if="this.user.register_role !== 'entrepreneur'" class="form-field__title">Company Name</label>
                                                <label v-else class="form-field__title">Name of Start
                                                    up</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                v-model="companyname" v-on:input="companynameHandler" class="form-field__input"
                                                type="text"
                                                :placeholder="this.user.register_role !== 'entrepreneur' ? 'Please enter Company name' : 'Please enter Start-Up name'">
                                            </div>
                                        </div><!-- //form-field -->

                                        <div class="companyname-popup" :class="{ 'active' : this.company.regCompanyPopup }">
                                            <p>This Startup name is taken. Do you want to join the StartUP team?</p>
                                            <div class="btn-group btn-group_secondary content-left">
                                                <a href="#" class="btn btn_primary">
                                                    <span @click="joinCompany(true)" class="btn__text">Yes</span>
                                                </a>
                                                <a href="#" @click="joinCompany(false)" class="btn btn_primary">
                                                    <span class="btn__text">No</span>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="companyname-notify-popup"
                                            :class="{ 'active' : this.company.regCompanyErrNotify }">
                                            This Startup name is taken, enter a new one
                                        </div>

                                    </div>

                                    <div class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label
                                                v-if="this.user.register_role == 'expert'"
                                                class="form-field__title">
                                                Field of Expertise
                                                    *</label>
                                                <label
                                                v-else-if="this.user.register_role == 'investor'"
                                                class="form-field__title">
                                                Type of Investments
                                                    *</label>
                                                <label
                                                v-else
                                                class="form-field__title">
                                                Industry of Start-Up
                                                    *</label>
                                            </div>

                                            <div v-if="this.user.register_role === 'investor'"
                                            class="form-field__content">
                                                <div :class="{'dropdown_active': selectToI}" class="dropdown">
                                                  <div @click.prevent="openDropDown('selectToI')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                        {{ company.companyInfo.investment ? company.companyInfo.investment : 'Type of Investments'}}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="industry in company.regCompanyInvestment" :key="industry.id" class="dropdown__item">
                                                        <div class="dropdown__link pointer"
                                                          :class="{'jcf-selected': industry.selected}"
                                                          @click.prevent="selectTypeOfInvestments(industry.id)">
                                                            <span>{{industry.value}}</span>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regToIErrNotify" class="form-errors">{{ this.regToIErrNotify }}</div>
                                            </div>

                                            <div v-else class="form-field__content">
                                                <div :class="{'dropdown_active': selectIndustry}" class="dropdown noselect">
                                                  <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                      {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                                        company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                                        user.register_role == 'entrepreneur' ? 'Please select Industry' : 'Please select Field of Expertise' }}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                                        <div class="custom-check dropdown__link">
                                                          <label class="custom-check__label">
                                                            <input class="custom-check__input"
                                                              v-model="industry.selected"
                                                              @change="inputChangeIndustry(index)"
                                                              type="checkbox" name="chekbox">
                                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                                                <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                                              </g>
                                                            </svg>
                                                            <span class="custom-check__text">{{industry.value}}</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regIndustryErrNotify" class="form-errors">{{ this.regIndustryErrNotify }}</div>
                                            </div>

                                        </div><!-- //form-field -->

                                    </div>
                                </div>

                                <div class="row">
                                    <div v-if="this.user.register_role == 'investor'" class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label
                                                class="form-field__title">
                                                Field of Focus *</label>
                                            </div>

                                             <div class="form-field__content">
                                                <div :class="{'dropdown_active': selectIndustry}" class="dropdown noselect">
                                                  <div @click.prevent="openDropDown('selectIndustry')" class="dropdown__init btn btn_select">
                                                    <span class="title-text">
                                                      {{ company.regCompanyIndustry.filter(a => a.selected).length ?
                                                        company.regCompanyIndustry.filter(a => a.selected).map(a => a.value).join(', ') :
                                                        user.register_role == 'entrepreneur' ? 'Please select Industry' : 'Please select Field of Expertise' }}
                                                    </span>
                                                    <svg class="ico" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.96967 0.21967C1.26256 -0.0732233 1.73744 -0.0732233 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732233 10.7374 -0.0732233 11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987437 0.676777 0.512563 0.96967 0.21967Z" fill="#5D6167"></path>
                                                    </svg>
                                                  </div>
                                                  <div class="dropdown__content one_column">
                                                    <ul class="dropdown__list">
                                                      <li v-for="(industry, index) in company.regCompanyIndustry" :key="industry.id" class="dropdown__item">
                                                        <div class="custom-check dropdown__link">
                                                          <label class="custom-check__label">
                                                            <input class="custom-check__input"
                                                              v-model="industry.selected"
                                                              @change="inputChangeIndustry(index)"
                                                              type="checkbox" name="chekbox">
                                                            <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g><rect id="check / in_active" x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"></rect>
                                                                <g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"></path></g>
                                                              </g>
                                                            </svg>
                                                            <span class="custom-check__text">{{industry.value}}</span>
                                                          </label>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div v-if="this.regIndustryErrNotify" class="form-errors">{{ this.regIndustryErrNotify }}</div>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-p-12">
                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Your City /Town *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <div class="form-field__content">
                                                  <input
                                                  id="pac-input"
                                                  class="form-field__input"
                                                  type="text"
                                                  :placeholder="'Please enter your City /Town'">
                                              </div>
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div v-if="this.user.register_role == 'investor'" class="steps__item step">
                            <div class="step__header">
                                <div class="step__number">
                                    <span class="number-text">2</span>
                                </div>
                                <div class="step__title">
                                    <span class="title-text">Questions</span>
                                </div>
                            </div>
                            <div class="step__content">
                                <div class="row">
                                    <div class="col col_12">
                                        <div class="switcher">
                                            <div class="switcher__action">
                                                <div class="custom-check custom-check_switcher">
                                                    <input id="business"
                                                            v-model="registeredBusiness"
                                                            class="custom-check__input  custom-check__input_switcher"
                                                            type="checkbox" name="checkbox" >
                                                    <svg class="custom-check__ico" width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="40" height="24" rx="12" fill="#00A961"/>
                                                        <g filter="url(#filter0_d)">
                                                            <circle cx="28" cy="12" r="9" fill="white"/>
                                                        </g>
                                                        <defs>
                                                            <filter id="filter0_d" x="14" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                                                <feOffset dy="2"/>
                                                                <feGaussianBlur stdDeviation="2.5"/>
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0.075 0 0 0 0 0.075 0 0 0 0 0.075 0 0 0 0.15 0"/>
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="switcher__content">
                                                <div class="wysiwyg wysiwyg_secondary-margin">
                                                    <h3>
                                                        <!-- v-if="this.user.register_role == 'expert'" -->
                                                        <label v-if="this.user.register_role == 'expert'" for="business">Is your Business a
                                                            registered Business?</label>
                                                        <label v-else-if="this.user.register_role == 'investor'" for="business">Are you Accredited Investor? <a href="#">What is an Accredited Investor?</a></label>
                                                        <label v-else for="business">Is your Start-Up a
                                                            registered Business?</label>
                                                    </h3>
                                                    <p v-if="this.user.register_role !== 'investor'">
                                                        (Having your Start-Up registered to the relevant
                                                        authorities and verified by our platform will
                                                        give the investors more comfort in connecting
                                                        and working with you)
                                                    </p>
                                                </div>
                                                <!-- <div v-show="registeredBusiness" class="form-field">
                                                    <div class="form-field__header">
                                                        <label v-if="this.user.register_role !== 'investor'" class="form-field__title">Registration Number: </label>
                                                        <span class="form-field__sub-title">(Not required to make account)</span>
                                                    </div>
                                                    <div class="form-field__content">
                                                        <div class="row">
                                                            <div class="col col_7 col_desktop-12">
                                                                <input type="text"
                                                                        v-model="registration_number"
                                                                        placeholder="Enter registration number"
                                                                        class="form-field__input">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                        <div
                        v-if="!this.user.access_token && !this.user.social_login"
                         class="steps__item step">
                            <div class="step__header">
                                <div class="step__number">
                                    <span class="number-text">{{this.user.register_role == 'investor' ? 3 : 2}}</span>
                                </div>
                                <div class="step__title">
                                    <span class="title-text">Registration</span>
                                </div>
                            </div>
                            <div class="step__content">
                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Email: *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false"
                                                v-model="email" class="form-field__input" type="email"
                                                        placeholder="Please enter email" :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Password *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false"
                                                v-model="password" v-on:input="passwordHandler"
                                                class="form-field__input" type="password"
                                                        placeholder="Please enter password" :required="true">
                                            </div>
                                        </div><!-- //form-field -->
                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header">
                                                <label class="form-field__title">Re-write Password
                                                    *</label>
                                            </div>
                                            <div class="form-field__content">
                                                <input
                                                autocomplete="false" class="form-field__input" type="password"
                                                        placeholder="Please enter password"
                                                        v-model="repassword" :required="true">
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="col col_6 col_mob-12">

                                        <div class="form-field">
                                            <div class="form-field__header content-between">
                                                <span class="form-field__title">Password strength:</span>
                                                <span class="form-field__progress" :class="passStrengthText">{{this.passStrengthValue}}</span>
                                            </div>
                                            <div class="form-field__content">
                                                <div class="progressbar">
                                                    <div :class="passStrengthClass">

                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- //form-field -->

                                    </div>

                                    <div class="password-notify-popup"
                                      :class="{ 'active' : this.regPassErrNotify }">
                                      {{ regPassErrNotify }}
                                  </div>

                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                </div>

                <div class="section__footer">

                    <div class="btn-group content-between">
                        <router-link :to="{name: 'account_create'}" class="btn btn_secondary">Cancel</router-link>
                        <button v-if="!this.user.social_login"
                            type="submit" class="btn btn_primary"><span
                            class="btn__text">Sign Up</span>
                        </button>
                        <button v-else
                            type="submit" class="btn btn_primary"><span
                                class="btn__text">Sign In</span>
                        </button>
                    </div>
                </div>

            </div>
        </form>
    </div>
        <!-- {{ info }} -->
</Reg-layouts>
</template>

<script>
    import store from '../store'
    import RegLayouts from "../layouts/Reg.vue"
    import { router } from '../router'
    import { passwordStrength } from 'check-password-strength'
    import VueClickAway from "vue3-click-away";

    const passCustomOptions = [
      {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0
      },{
        id: 1,
        value: "Weak",
        minDiversity: 2,
        minLength: 4
      },{
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 6
      },{
        id: 3,
        value: "Strong",
        minDiversity: 3,
        minLength: 8
    }];

    export default {
        name: "Register",
        components: {
            RegLayouts,
        },
        directives: {
            ClickAway: VueClickAway,
        },
        data() {
            return {
                firstname: '',
                lastname: '',
                companyname: '',
                registration_number: '',
                email: '',
                password: '',
                repassword: '',
                selectedCountry: '',
                selectedState: '',
                selectedCity: '',
                registeredBusiness: false,
                passStrengthValue: '',
                passStrengthId: '',
                passStrengthClass: 'progressbar__0',
                passStrengthText: 'strength-text__0',
                regPassErrNotify: '',
                regIndustryErrNotify: '',
                regToIErrNotify: '',
                location: '',
                location_list: false,


                selectIndustry: false,
                selectToI: false,
                selectSoF: false,
            }
        },
        beforeMount() {
            if(!this.user.register_role){
                router.push({name: "account_create"});
            }
            store.dispatch('GET_COMPANY_INDUSTRY', this.user.register_role);
        },
        mounted() {
            if(this.user.social_names) {
                this.firstname = this.user.social_names.firstname;
                this.lastname = this.user.social_names.lastname;
            }
            var self = this;
            var options = {
                types: ['(cities)']
            };
            const input = document.getElementById("pac-input");
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            this.location = google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                self.selectedCity = place.address_components[0].long_name;
                for (let index = 0; index < place.address_components.length; index++) {
                    if(place.address_components[index].types[0] == "country"){
                        self.selectedCountry = place.address_components[index].long_name;
                    }
                }
            });

            setTimeout(() => {input.setAttribute('autocomplete','new-password');}, 1000);
            input.addEventListener('click', function() {
                input.setAttribute('autocomplete','new-password');
            });
        },
        methods: {
            chooseLocation(city, country){
                document.getElementById("pac-input").value = city+", "+country;
                this.selectedCity = city;
                this.selectedCountry = country;
                this.location_list = false;
            },
            companynameHandler: function() {
                if(this.companyname.length >= 1) {
                    store.dispatch('SEARCH_COMPANY', this.companyname);
                } else {
                    store.dispatch('RESET_COMPANY_NAME');
                }
            },
            passwordHandler: function() {
                if(this.password.length <=0){
                    this.passStrengthValue = '';
                    this.passStrengthId = '';
                    this.passStrengthClass = 'progressbar__0';
                    this.passStrengthText = 'strength-text__0';
                  } else {
                    this.passStrengthValue = passwordStrength(this.password, passCustomOptions).value;
                    this.passStrengthId = passwordStrength(this.password, passCustomOptions).id;

                    switch (this.passStrengthId) {
                        case 0:
                            this.passStrengthClass = 'progressbar__25';
                            this.passStrengthText = 'strength-text__25';
                            break;
                        case 1:
                            this.passStrengthClass = 'progressbar__50';
                            this.passStrengthText = 'strength-text__50';
                            break;
                        case 2:
                            this.passStrengthClass = 'progressbar__75';
                            this.passStrengthText = 'strength-text__75';
                            break;
                        case 3:
                            this.passStrengthClass = 'progressbar__100';
                            this.passStrengthText = 'strength-text__100';
                            break;
                    }
                }
            },
            joinCompany: function(arg) {
                if(arg) {
                    store.dispatch('REG_ACCEPT_JOIN_COMPANY');
                } else {
                    store.dispatch('REG_REJECT_JOIN_COMPANY');
                }
            },
            signup: function() {
              console.log(this.company.regCompanyIndustry.filter(a => a.selected).length)
                this.regPassErrNotify = '';
                if(!this.user.social_login && this.password !== this.repassword) {
                  this.regPassErrNotify = 'Password and Re-Password do not match';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }
                else if(!this.user.social_login && passwordStrength(this.password, passCustomOptions).id < 3) {
                  this.regPassErrNotify = 'Capital letters and numbers are required.';
                  setTimeout(() => {
                    this.regPassErrNotify = '';
                  }, 3000);
                  return;
                }

                if(this.company.regCompanyIndustry.filter(a => a.selected).length <= 0){
                    this.regIndustryErrNotify = 'This field is required.';
                    setTimeout(() => {this.regIndustryErrNotify = '';}, 3000);
                    return;
                }

                if(this.user.register_role == "investor" && this.company.regCompanyInvestment.filter(a => a.selected).length <= 0){
                    this.regToIErrNotify = 'This field is required.';
                    setTimeout(() => {this.regToIErrNotify = '';}, 3000);
                    return;
                }

                var data = {};
                var company = {};
                data.account_type = this.user.register_role;
                data.city = this.selectedCity;
                data.country = this.selectedCountry;

                company.id= this.company.regCompanyId;
                company.companyname = this.companyname;
                company.companycity = this.selectedCity;
                company.companycountry =  this.selectedCountry;
                company.registration_number = this.registration_number;
                company.companyindustry = this.company.regCompanyIndustry.filter(a => a.selected).map(a => a.id);
                company.type_of_investment = this.company.regCompanyInvestment.filter(a => a.selected).map(a => a.id)[0] ?? "";

                data.company = company;
                data.email = this.email;
                data.lastname = this.lastname;
                data.firstname = this.firstname;
                data.password = this.password;
                data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

                store.dispatch('REG_NEW_ACCOUNT', data);
            },
            popupRemove: function() {
                store.dispatch('USER_REG_ERROR', false);
            },

            openDropDown(target) {
              let old = this[target];
                this.selectIndustry = false;
                this.selectToI = false;
                this.selectSoF = false;
                this[target] = !old;
            },
            closeSelect(e) {
              if (!e.target.closest(".dropdown_active")) {
                this.selectIndustry = false;
                this.selectToI = false;
                this.selectSoF = false;
              }
            },

            inputChangeIndustry(current) {
              console.log(this.user.register_role)
              if (this.user.register_role == 'investor') return;
              let self = this;
              let count = 0;
              let indexes = [];
              this.company.regCompanyIndustry.forEach(function(item, index) {
                if (item.selected) {
                  indexes.push(index);
                  count ++;
                  if (count > 4) {
                    if (current == indexes[indexes.length - 2])
                      self.company.regCompanyIndustry[indexes[indexes.length - 3]].selected = false;
                    else
                      self.company.regCompanyIndustry[indexes[indexes.length - 2]].selected = false;
                  }
                }
              });
            },

            selectTypeOfInvestments(id) {
              let self = this;
              self.company.regCompanyInvestment.forEach((item, index) => {
                if (item.id == id) {
                  self.company.regCompanyInvestment[index].selected = true;
                  self.company.companyInfo.investment = item.value;
                } else {
                  self.company.regCompanyInvestment[index].selected = false;
                }
              });
              this.selectToI = false;
            },


        },
        computed: {
            company: () => store.state.company,
            user: () => store.state.user
        },

        created() {
          document.addEventListener('click', this.closeSelect);
        },
        beforeUnmount() {
          document.removeEventListener('click', this.closeSelect)
        }
    }
</script>
